import { ProfilePage } from 'containers/profile/ProfilePage'
import { TwoFAPage } from 'containers/profile/TwoFAPage'
import { SessionContext } from 'contexts/SessionContext'
import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { lazyLoad } from 'utils/Loadable'

export function AdminRoutes() {
  const [session] = React.useContext(SessionContext)

  return (
    <Switch>
      <Route path="/admin">
        <Route
          path={'/admin/dashboard'}
          exact={true}
          component={lazyLoad(
            () => import('containers/admin/dashboard/DashboardPage'),
            mod => mod.AdminDashboardPage,
          )}
        />
        <Route path={'/admin/profile/twofa'} exact={true} component={TwoFAPage} />
        <Route path={'/admin/profile'} exact={true} component={ProfilePage} />
        <Route
          path={'/admin/users'}
          exact={true}
          component={lazyLoad(
            () => import('containers/admin/users/UsersPage'),
            mod => mod.UsersPage,
          )}
        />
        <Route
          path={'/admin/partners/:partnerId/:merchantId'}
          exact={true}
          component={lazyLoad(
            () => import('containers/admin/merchants/MerchantDetailPage'),
            mod => mod.MerchantDetailPage,
          )}
        />
        <Route
          path={'/admin/partners'}
          exact={true}
          component={lazyLoad(
            () => import('containers/admin/merchants/MerchantsPage'),
            mod => mod.MerchantsPage,
          )}
        />
        <Route
          path={'/admin/fees'}
          exact={true}
          component={lazyLoad(
            () => import('containers/admin/fee/FeesPage'),
            mod => mod.FeesPage,
          )}
        />

        <Route
          path={'/admin/depositrequests'}
          exact={true}
          component={lazyLoad(
            () => import('containers/admin/deposit/AdminDepositRequestsPage'),
            mod => mod.AdminDepositRequestsPage,
          )}
        />

        <Route
          path={'/admin/depositrequeststransfers'}
          exact={true}
          component={lazyLoad(
            () => import('containers/admin/deposit/AdminDepositRequestsTransferOnlyPage'),
            mod => mod.AdminDepositRequestsTransferOnlyPage,
          )}
        />

        <Route
          path={'/admin/withdraws'}
          exact={true}
          component={lazyLoad(
            () => import('containers/admin/withdraws/WithdrawsPage'),
            mod => mod.WithdrawsPage,
          )}
        />

        <Route
          path={'/admin/networks'}
          exact={true}
          component={lazyLoad(
            () => import('containers/admin/network/NetworksPage'),
            mod => mod.NetworksPage,
          )}
        />

        <Route
          path={'/admin/callbacks'}
          exact={true}
          component={lazyLoad(
            () => import('containers/admin/callbacks/CallbacksMonitoring'),
            mod => mod.AdminCallbacksMonitoring,
          )}
        />

        <Route
          path={'/admin/partnernetwork'}
          exact={true}
          component={lazyLoad(
            () => import('containers/admin/partnernetwork/PartnerNetworkPage'),
            mod => mod.PartnerNetworkPage,
          )}
        />

        <Route
          path={'/admin/settlements'}
          exact={true}
          component={lazyLoad(
            () => import('containers/admin/settlements/SettlementsPage'),
            mod => mod.SettlementPage,
          )}
        />

        <Route
          path={'/admin/suspectedtransactions'}
          exact={true}
          component={lazyLoad(
            () => import('containers/admin/suspectedtransaction/SuspectedTrxPage'),
            mod => mod.SuspectedTrxPage,
          )}
        />

        <Route
          path={'/admin/dttot'}
          exact={true}
          component={lazyLoad(
            () => import('containers/dttot/DttotPage'),
            mod => mod.DttotPage,
          )}
        />

        <Route path="/admin" exact={true} render={() => <Redirect to="/admin/dashboard" />} />
      </Route>
    </Switch>
  )
}
