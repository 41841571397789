import { PartnerDetail } from './PartnerOnboarding'

export class Partner {
  constructor(
    public id: undefined | string,
    public name: string,
    public code: string,
    public settlementMethod: string,
    public callbackUrl?: string,
    public partnerDetail?: PartnerDetail | undefined,
  ) {}
}

export enum EPartnerSettlementMethod {
  DEFAULT = 'default',
  AUTOSETTLE = 'autosettle',
  MANUAL = 'manual',
}

export enum PartnerTypes {
  Withdraw = 'withdraw',
  Deposit = 'deposit',
}
